import { createContext } from "react";
import { UserStore } from "./userStore";
import { AuthStore } from "./authStore";
import { RegistrationStore } from "./registrationStore";
import { ToastStore } from "./toastStore";
import { DebitStore } from "./debitStore";

export class RootStore {
  constructor() {
    this.authStore = new AuthStore();
    this.userStore = new UserStore();
    this.registrationStore = new RegistrationStore();
    this.debitStore = new DebitStore();
    this.toastStore = new ToastStore();
  }
}
export const rootStore = new RootStore();
export const rootStoreContext = createContext(rootStore);
