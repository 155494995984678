import React from "react";
import { createPortal } from "react-dom";
import { Modal } from "antd";
import { Link } from "react-router-dom";

import iconLoanAmount from "assets/icons/loan_amount.svg";
import iconProfile from "assets/icons/profile.svg";

export default function PopupProfile({ isOpenModal, handleOpen }) {
  return createPortal(
    <Modal
      className="popup-profile"
      title=" "
      open={isOpenModal}
      onCancel={handleOpen}
      centered
      destroyOnClose
      footer={null}
      maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
      zIndex={1400}
    >
      <div className="list_item">
        <div className="item">
          <Link to="/wallet/loan-history">
            <div className="picture">
              <img src={iconLoanAmount} alt="Xem khoản vay của bạn" />
            </div>
            <div className="title">Xem khoản vay của bạn</div>
          </Link>
        </div>
        <div className="item">
          <Link to="/wallet/me/information">
            <div className="picture">
              <img src={iconProfile} alt="Xem thông tin hồ sơ" />
            </div>
            <div className="title">Xem thông tin hồ sơ</div>
          </Link>
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
}
