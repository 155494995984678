import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Select } from "antd";
import { optionBank } from "_common/constants/bank";
import { useQuery } from "@tanstack/react-query";
import { SystemBankApi } from "states/api/systemBank";

const VerificationBank = observer(() => {
  const { data } = useQuery(
    ["bank", "list"],
    () =>
      SystemBankApi.getList({
        params: {
          page: 1,
          limit: 100,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    window.onload = () => {
      const myInput = document.getElementById("bank_account");
      myInput.onpaste = (e) => {
        e.preventDefault();
        // alert("Không cho phép sao chép");
        return false;
      };
    };
  }, []);

  return (
    <div className="group">
      <div className="group_title">Tài khoản ngân hàng</div>
      <Form.Item
        name="bank_id"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Chọn ngân hàng thụ hưởng"
          options={data?.data.map((bank) => ({
            value: bank.id,
            label: bank?.bank_code,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="bank_account"
        id="bank_account"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Số tài khoản" />
      </Form.Item>
      <Form.Item
        name="bank_user_name"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Tên chủ tài khoản" />
      </Form.Item>
    </div>
  );
});

export default VerificationBank;
