import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import moment from "moment";
import { Button, Form } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { useStores } from "_common/hooks";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import VerificationInfo from "./VerificationInfo";
import VerificationBank from "./VerificationBank";
import VerificationImage from "./VerificationImage";
import VerificationRelative from "./VerificationRelative";

const MeInfoVerification = observer(() => {
  const {
    toastStore,
    authStore: { user },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(
    (variables) =>
      ProfileApi.update({
        params: {
          ...variables,
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        toastStore.success(res?.msg || "Thao tác thành công");
        form.resetFields();
        navigate("/wallet/loan");
      },
      onError: (error) => {
        toastStore.error(error?.message ?? "Vui lòng thử lại!");
      },
    }
  );

  // useEffect(() => {
  //   if (!user) return;
  //   form.setFieldsValue({ ...user, dob: user?.dob ? moment(user?.dob) : null });
  // }, [user]);

  const handleSubmit = (value) => {
    if (!form.getFieldValue("front_image"))
      return toastStore.error("Vui lòng tải CMND/CCCD mặt trước");
    if (!form.getFieldValue("back_image"))
      return toastStore.error("Vui lòng tải CMND/CCCD mặt sau");
    if (!form.getFieldValue("self_image"))
      return toastStore.error("Vui lòng tải ảnh chân dung");
    mutate({
      ...value,
      ...{
        front_image: form.getFieldValue("front_image"),
        back_image: form.getFieldValue("back_image"),
        self_image: form.getFieldValue("self_image"),
      },
    });

    return null;
  };

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Xác thực tài khoản" isShowBack />
      <div className="header-page-overlay" />
      <div className="verify-container">
        <div className="verify">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <VerificationImage form={form} />
            <VerificationInfo form={form} />
            <VerificationRelative form={form} />
            <VerificationBank form={form} />
            <Form.Item className="text-center">
              <Button htmlType="submit" className="btn-submit">
                HOÀN TẤT
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
});

export default MeInfoVerification;
