import React from "react";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Link } from "react-router-dom";

export default function NotVerify() {
  return (
    <div className="not_verified">
      <div className="verify_title">Xác thực tài khoản</div>
      <div className="content">
        <div className="icon">
          <HowToRegIcon />
        </div>
        <div className="content_detail">
          <p>Bổ sung CMND/CCCD và chân dung để hoàn tất định danh</p>
          <p>
            <Link to="/wallet/me/info/verification">Xác thực ngay</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
