import React from "react";
import { BellFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import classNames from "classnames";

function WalletHeader({
  title,
  isShowBack = false,
  showUser = false,
  isShowBell = false,
}) {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  return (
    <div
      className={classNames("header-page", {
        "header-home": showUser,
      })}
    >
      <div className="container">
        <div className="list_item_header">
          {isShowBack && (
            <span className="header-back" onClick={() => navigate(-1)} />
          )}
          {showUser && (
            <div className="info">
              <p>Xin chào,</p>
              <p>{user?.first_name}</p>
            </div>
          )}
          <p>{title}</p>
          {isShowBell && (
            <BellFilled
              style={{
                fontSize: "1.25rem",
                position: "absolute",
                right: "5%",
                bottom: "50%",
                transform: "translate(-50%, 50%)",
              }}
              onClick={() => navigate("/wallet/notify")}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default WalletHeader;
