import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut5 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <h4 className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU">
        Miễn lãi lên đến 45 ngày
      </h4>
      Kể từ ngày tiêu dùng qua thẻ đến ngày đến hạn thanh toán của thẻ, Quý
      khách được Petromex hỗ trợ miễn lãi tối đa tới 45 ngày.
      <br />
      <br />
      Mọi chi tiết thêm về thể lệ chương trình, Khách hàng vui lòng tham khảo
      tại : website của Petromex :&nbsp;
      <a
        href="https://petrolimexcard.com"
        role="button"
        className="sc-AxhUy sc-qQmou dVBRWK"
      >
        https://petrolimexcard.com
      </a>
      hoặc liên hệ hotline:&nbsp;
      <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
        1900633554
      </span>
    </div>
  );
});

export default FindOut5;
