import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

import iconUserInfo from "assets/img/Me/user_info.svg";
import iconBankInfo from "assets/img/Me/bank.svg";
import iconSupport from "assets/img/Me/support.svg";
import iconLogout from "assets/img/Me/logout.svg";
import imgAvatar from "assets/img/Me/default-avatar.png";
import NotVerify from "_common/component/NotVerify";
import { handleOpenCSKH } from "_common/utils/helper";
import Copying from "_common/component/Copying";

const Me = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, clear, isKYC, general },
    toastStore,
  } = useStores();
  const navigate = useNavigate();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
      navigate("/");
    },
    onError: (error) => {
      clear();
      toastStore.error(error?.message ?? "Vui lòng thử lại!");
    },
  });

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <>
      <WalletHeader title="Thông tin hồ sơ" />
      <div className="header-page-overlay" />
      <div className="wallet-home-page">
        <div className="container me-container">
          <div className="avatar">
            <div className="picture">
              {user?.avatar_url ? (
                <img src={user?.avatar_url} alt="User Avatar" />
              ) : (
                <img src={imgAvatar} alt="User Avatar" />
              )}
            </div>
            <div className="info">
              {isKYC ? (
                <>
                  <p className="verification verified">
                    <TaskAltOutlinedIcon />
                    <span>Đã xác minh</span>
                  </p>
                  <p className="full_name">
                    {user?.first_name || user?.username}
                  </p>
                </>
              ) : (
                <>
                  <p className="verification not_verified">
                    <InfoOutlinedIcon />
                    <span>Chưa xác thực</span>
                  </p>
                  <p className="full_name" />
                </>
              )}
            </div>
          </div>
          <div className="line" />
          {!isKYC && <NotVerify />}
          <div className="list_button">
            <div className="item">
              <Link
                to={isKYC ? "/wallet/me/information" : "/wallet/me"}
                className="acc_not_verified"
              >
                <div className="picture">
                  <img src={iconUserInfo} alt="user info" />
                </div>
                <div className="title">Thông tin cá nhân</div>
              </Link>
            </div>
            {general?.bank_number && (
              <div className="item">
                <Link
                  to={isKYC ? "/wallet/me/bank" : "/wallet/me"}
                  className="acc_not_verified"
                >
                  <div className="picture">
                    <img src={iconBankInfo} alt="user info" />
                  </div>
                  <div className="title">Tài khoản thanh toán</div>
                </Link>
              </div>
            )}

            <div className="item" style={{ marginTop: 25 }}>
              <Link to="/wallet/me" onClick={() => handleOpenCSKH()}>
                <div className="picture">
                  <img src={iconSupport} alt="support" />
                </div>
                <div className="title">Liên hệ tư vấn - hỗ trợ</div>
              </Link>
            </div>
            {general?.hotline && (
              <div className="item" style={{ marginTop: 25 }}>
                <a to={`tel:${general?.hotline}`}>
                  <div className="picture">
                    <img src={iconSupport} alt="support" />
                  </div>
                  <div className="title">Hotline {general?.hotline}</div>
                </a>
              </div>
            )}
          </div>
          <div className="logout">
            <Button onClick={onLogout}>
              <img src={iconLogout} alt="signout" />
              Đăng xuất
            </Button>
          </div>
          <div style={{ marginTop: "10%" }}>
            <Copying />
          </div>
        </div>
      </div>
    </>
  );
});

export default Me;
