import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { AuthApi } from "states/api";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { useStores } from "_common/hooks";

import iconPhone from "assets/icons/phone.svg";
import iconLock from "assets/icons/lock.svg";

const Register = observer(() => {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
        navigate("/wallet");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleLogin = (value) => {
    if (isLoading) return;
    mutate(value);
  };

  return (
    <div className="full signin">
      <div className="background" style={{ height: "360.488px" }} />
      <div id="container" className="container">
        <div className="user_form user_signin">
          <div className="col_right">
            <div className="logo">
              <Link to="/auth/login" target="_self">
                <img src={authStore.logo} alt="Logo" />
              </Link>
              <p>Uy tín dẫn bước</p>
            </div>
            <div className="user_signin-title">Đăng ký</div>
            <div className="user_signin-short">
              Đăng ký để sử dụng dịch vụ của chúng tôi
            </div>
            <div className="user_signin-content">
              <Form
                form={form}
                layout="vertical"
                onFinish={handleLogin}
                autoComplete="off"
              >
                <Form.Item
                  name="username"
                  className="form-group-area"
                  rules={[
                    {
                      required: true,
                      message: "Nhập số điện thoại",
                    },
                  ]}
                >
                  <Input
                    placeholder="Số điện thoại"
                    prefix={<img src={iconPhone} alt="" />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  className="form-group-area"
                  rules={[
                    {
                      required: true,
                      message: "Nhập mật khẩu",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Mật khẩu"
                    prefix={<img src={iconLock} alt="" />}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  className="form-group-area"
                  rules={[
                    {
                      required: true,
                      message: "Nhập lại mật khẩu",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Nhập lại mật khẩu"
                    prefix={<img src={iconLock} alt="" />}
                  />
                </Form.Item>
                <Form.Item className="row_btn">
                  <Button id="btn-next" htmlType="submit" className="btn">
                    <span>Đăng ký</span>
                  </Button>
                </Form.Item>
                <div className="register">
                  Đã có tài khoản?
                  <Link to="/auth/login">Đăng nhập</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Register;
