import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";

const WalletNotification = observer(() => (
  <>
    <WalletHeader title="Thông báo" isShowBack />
    <div className="header-page-overlay" />
    <div className="wallet-home-page">
      <div className="container">
        <div className="box-content" id="user_notifications">
          <form method="post" acceptCharset="utf-8" id="form_notifications">
            <div className="tab-content">
              <div className="empty text-center" style={{ paddingTop: "1rem" }}>
                Bạn chưa có thông báo nào
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
));

export default WalletNotification;
