import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { socketUrl } from "_common/constants/common";
import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";

let sock = null;

export const NotifyContext = createContext({});

export default function NotifyProvider({ children }) {
  const queryClient = useQueryClient();
  const [notify, setNotify] = useState(null);

  const onJoinRoom = (room_id) => {
    sock.emit("join_room", {
      room_id,
    });
  };

  const onLeaveRoom = (room_id) => {
    sock.emit("leave_room", {
      room_id,
    });
  };

  const onNotify = (data) => {
    setNotify(data);

    if (data?.type === "new_message") {
      queryClient.invalidateQueries(["payment", "list_withdraw"]);
      queryClient.invalidateQueries(["get_profile"]);
    }
  };

  const pushNotify = useCallback((data) => {
    sock.emit("betGame", { ...data, profile_id: data?.user_id });
  }, []);

  useEffect(() => {
    sock = io(socketUrl, {
      transports: ["websocket"],
    });

    sock.on("get_notification", onNotify);
    return () => {
      sock.close();
    };
  }, []);

  const returnDefault = useMemo(
    () => ({
      notify,
      setNotify,
      pushNotify,
      onJoinRoom,
      onLeaveRoom,
    }),
    [notify]
  );

  return (
    <NotifyContext.Provider value={returnDefault}>
      {children}
    </NotifyContext.Provider>
  );
}

export function useNotify() {
  return useContext(NotifyContext);
}
