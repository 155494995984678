import { get } from "lodash";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class DebitStore {
  duration = 6;

  amount = 0;

  rate = 1;

  isModalContract = false;

  isShowPopupDeniedWithdraw = false;

  reason = {};

  item_contract = {};

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "DebitStore",
      properties: ["duration", "amount", "rate"],
      storage: window.localStorage,
    });
  }

  reset = () => {
    this.duration = 6;
    this.amount = 0;
    this.rate = 1;
  };

  openModalContract = (value, item) => {
    this.isModalContract = value;
    this.item_contract = item;
  };

  openModalDenied = (value, item) => {
    this.isShowPopupDeniedWithdraw = value;
    this.reason = item;
  };

  setRate = (value) => {
    this.rate = value ?? this.rate;
  };

  setAmount = (value) => {
    this.amount = value ?? this.amount;
  };

  setDuration = (value) => {
    this.duration = value ?? this.duration;
  };

  get payAmountFirst() {
    const rateConvert = this.rate / 100;
    // @ts-ignore
    const parseAmount = `${this.amount}`
      .replaceAll(".", "")
      .replaceAll(",", "");
    const debitPerMonth = Number(parseAmount) * rateConvert;
    const amountPerTotal = Number(parseAmount) / Number(this.duration);

    return Math.ceil(debitPerMonth + amountPerTotal);
  }
}
