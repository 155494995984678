import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Form, Button } from "antd";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthApi } from "states/api";

const Step5 = observer(() => {
  const {
    authStore,
    registrationStore: {
      dataStep1,
      dataStep2,
      dataStep3,
      dataStep4,
      dataStep5,
      updateDataStep5,
    },
    toastStore,
  } = useStores();
  const canvasRef = useRef();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
        toastStore.success("Đăng ký thành công!");
        navigate("/auth/registration/success");
      },
      onError: (error) => {
        toastStore.error(error?.message ?? "Vui lòng thử lại!");
      },
    }
  );

  const handleSubmit = () => {
    if (isLoading) return;

    const signImage = canvasRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    updateDataStep5({
      sign_image: signImage,
    });

    mutate({
      ...dataStep1,
      ...dataStep2,
      ...dataStep3,
      ...dataStep4,
      ...dataStep5,
      sign_image: signImage,
    });
  };

  const handleRenewCanvas = () => {
    canvasRef.current.clear();
  };

  return (
    <section id="step-body">
      <div className="wrapper step">
        <div className="content mb-40">
          <div className="max-720 m-auto outner-form">
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              autoComplete="off"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "1rem",
                marginTop: "1rem",
              }}
            >
              <Form.Item>
                <p className="txt_r_l">
                  Quý khách đặt điều kiện đăng ký thẻ hạn mức: 100,000,000VND
                </p>
              </Form.Item>
              <Form.Item>
                <p>Ký vào khung bên dưới</p>
                <SignatureCanvas
                  ref={canvasRef}
                  penColor="black"
                  canvasProps={{
                    height: 400,
                    className: "sigCanvas",
                  }}
                />
                <Button
                  type="button"
                  className="sign-canvas-renew-btn"
                  onClick={handleRenewCanvas}
                >
                  Làm mới
                </Button>
              </Form.Item>
              <Form.Item>
                <div className="text-right">
                  <Button
                    id="btn-next"
                    htmlType="submit"
                    className="btn btn_m yellow-btn"
                  >
                    <span>Tiếp tục</span>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Step5;
