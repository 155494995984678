import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import imgAvatar from "assets/img/Me/default-avatar.png";
import iconUser from "assets/img/Me/user_icon.svg";
import iconBankCard from "assets/img/Me/bank_card.svg";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Information = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user },
  } = useStores();
  const [isShowBank, setIsShowBank] = useState(false);
  const [isShowBankNumber, setIsShowBankNumber] = useState(false);

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div className="wallet-home-page">
      <WalletHeader title="Thông tin cá nhân" isShowBack />
      <div className="header-page-overlay" />
      <div className="container box-account">
        <div className="box_avatar">
          <div className="picture">
            <div id="show_picture" className="pic_view text-center">
              <div className="item">
                {user?.avatar_url ? (
                  <img src={user?.avatar_url} alt="User Avatar" />
                ) : (
                  <img src={imgAvatar} alt="User Avatar" />
                )}
              </div>
            </div>
          </div>
          <div className="info">
            <p className="full_name">{user?.username}</p>
          </div>
        </div>
        <div className="col-12 group user_information">
          <div className="group_title">
            <div className="img">
              <img src={iconUser} alt="user_info" />
            </div>
            <span>Thông tin cá nhân</span>
          </div>
          <div className="form-group">
            <div className="title">Họ tên</div>
            <p>{user?.first_name}</p>
          </div>
          <div className="form-group">
            <div className="title">Giới tính</div>
            <p>{user?.gender}</p>
          </div>
          <div className="form-group">
            <div className="title">Địa chỉ</div>
            <p>{user?.address}</p>
          </div>
          <div className="form-group">
            <div className="title">Số CMND/CCCD</div>
            <p>{user?.id_proof_number}</p>
          </div>
          <div className="form-group">
            <div className="title">Ngày tháng năm sinh</div>
            <p>{user?.dob}</p>
          </div>
          <div className="form-group">
            <div className="title">Học vấn</div>
            <p>{user?.employ_status}</p>
          </div>
          <div className="form-group">
            <div className="title">Tình trạng hôn nhân</div>
            <p>{user?.marriage}</p>
          </div>
          <div className="form-group">
            <div className="title">Nghề nghiệp</div>
            <p>{user?.job}</p>
          </div>
          <div className="form-group">
            <div className="title">Thu nhập</div>
            <p>{user?.salary}</p>
          </div>
          <div className="form-group">
            <div className="title">Mục đích khoản vay</div>
            <p>{user?.debit_reason}</p>
          </div>
          <div className="form-group">
            <div className="title">Số điện thoại người thân</div>
            <p>{user?.relative_phone}</p>
          </div>
          <div className="form-group">
            <div className="title">Mối quan hệ với người thân</div>
            <p>{user?.relative_type}</p>
          </div>
        </div>
        <div className="col-12 group bank_information">
          <div className="group_title">
            <div className="img">
              <img src={iconBankCard} alt="bank_info" />
            </div>
            <span>Tài khoản ngân hàng</span>
          </div>
          <div className="button_show_bank">
            <span onClick={() => setIsShowBank(!isShowBank)}>
              {isShowBank ? (
                <>
                  Ẩn <KeyboardArrowUpIcon />
                </>
              ) : (
                <>
                  Xem <KeyboardArrowDownIcon />
                </>
              )}
            </span>
          </div>
          <div className={`content_bank ${!isShowBank && "hide"}`}>
            <div className="form-group">
              <div className="title">Tên ngân hàng</div>
              <p>{user?.bank?.bank_code || user?.bank_name}</p>
            </div>
            <div className="form-group">
              <div className="title">STK ngân hàng</div>
              <p>
                <span className="bank_copy">
                  {isShowBankNumber
                    ? user?.bank_account
                    : user?.bank_number_display}
                </span>

                <FontAwesomeIcon
                  icon={isShowBankNumber ? faEyeSlash : faEye}
                  onClick={() => setIsShowBankNumber(!isShowBankNumber)}
                />
              </p>
            </div>
            <div className="form-group">
              <div className="title">Tên thụ hưởng</div>
              <p>{user?.bank_user_name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Information;
