/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Marquee from "react-fast-marquee";
import { useStores } from "_common/hooks";

import iconBell from "assets/icons/bell.svg";

import walletBanner1 from "assets/img/WalletHome/banner_vay_tien_2-01.png";
import walletBanner2 from "assets/img/WalletHome/group_19578.png";

import imgNav1 from "assets/img/WalletHome/dki_vay.png";
import imgNav2 from "assets/img/WalletHome/ho_so.png";
import imgNav3 from "assets/img/WalletHome/vi_tien.png";
import imgNav4 from "assets/img/WalletHome/ho_tro.png";
import PopupProfile from "_common/component/Popup/PopupProfile";
import { handleOpenCSKH } from "_common/utils/helper";
import Copying from "_common/component/Copying";

const WalletHome = observer(() => {
  const {
    authStore: { user, general },
  } = useStores();
  const [isShowPopupProfile, setIsShowPopupProfile] = useState(false);

  return (
    <>
      <div className="wallet-home-page">
        <div className="container">
          <div className="header_home">
            <div className="name">{`Xin chào, ${user?.username}!`}</div>
            <div className="notic">
              <Link to="/wallet/notification">
                <img src={iconBell} alt="notification" />
                <span className="num">0</span>
              </Link>
            </div>
          </div>
        </div>
        <Marquee className="withdraw">
          <p>09*****123 đã rút 100.000.000 vnđ</p>
          <p>09*****123 đã rút 100.000.000 vnđ</p>
        </Marquee>
        <div className="container">
          <div id="main_slide">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              centeredSlides
              loop
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <img src={walletBanner1} alt="" style={{ width: "100%" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={walletBanner2} alt="" style={{ width: "100%" }} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="navigator">
            <div className="item col-6">
              <Link to="/wallet/loan" className="item-container">
                <div className="picture">
                  <img src={imgNav1} alt="Đăng ký vay" />
                </div>
                <div className="title">Đăng ký vay</div>
              </Link>
            </div>
            <div className="item col-6">
              <div
                className="button item-container"
                onClick={() => setIsShowPopupProfile(true)}
              >
                <div className="picture">
                  <img src={imgNav2} alt="Hồ sơ của bạn" />
                </div>
                <div className="title">Hồ sơ của bạn</div>
              </div>
            </div>
            <div className="item col-6">
              <Link to="/wallet/card" className="item-container">
                <div className="picture">
                  <img src={imgNav3} alt="Ví tiền của bạn" />
                </div>
                <div className="title">Ví tiền của bạn</div>
              </Link>
            </div>
            <div className="item col-6">
              <a href={`tel:${general?.hotline}`} className="item-container">
                <div className="picture">
                  <img src={imgNav4} alt="Hỗ trợ khách hàng" />
                </div>
                <div className="title">Hỗ trợ khách hàng</div>
              </a>
            </div>
          </div>
          <Copying />
        </div>
      </div>
      {isShowPopupProfile && (
        <PopupProfile
          isOpenModal={isShowPopupProfile}
          handleOpen={() => setIsShowPopupProfile(false)}
        />
      )}
    </>
  );
});

export default WalletHome;
