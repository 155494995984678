import React, { useEffect, useRef } from "react";
import { Button, Modal } from "antd";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { createPortal } from "react-dom";

import Contract1 from "./Contract1";
import Contract2 from "./Contract2";

const ModalContractDetail = observer(() => {
  const {
    authStore: { general },
    debitStore: { item_contract, isModalContract, openModalContract },
  } = useStores();

  const renderBody = () => {
    switch (general?.contract_template) {
      case "mau_1":
        return <Contract1 item={item_contract} />;

      case "mau_2":
        return <Contract2 item={item_contract} />;

      default:
        return <Contract1 item={item_contract} />;
    }
  };

  const onClose = () => {
    openModalContract(false, null);
  };

  return createPortal(
    <Modal
      open={isModalContract}
      onCancel={() => onClose()}
      maskClosable={false}
      className="modal_contract"
      zIndex={1800}
      footer={
        <Button
          className="btn-primary"
          style={{ marginLeft: 12 }}
          onClick={() => onClose()}
        >
          Đóng
        </Button>
      }
    >
      <div>{renderBody()}</div>
    </Modal>,
    document.getElementById("root")
  );
});

export default ModalContractDetail;
