import React, { useState } from "react";
import { formatNumber } from "remix-dls";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";
import Swal from "sweetalert2";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import iconBankCard from "assets/icons/bank_card.svg";
import iconMoney from "assets/icons/money.svg";
import iconAngleSmallRight from "assets/icons/angle-small-right.svg";
import imgBankCard from "assets/img/WalletHome/acb.png";
import PopupWithdraw from "_common/component/Popup/PopupWithdraw";
import { useMutation } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import Copying from "_common/component/Copying";

const CardWallet = observer(() => {
  const {
    authStore: { user, loan, logo },
    debitStore: { openModalDenied },
  } = useStores();
  const [isShowPopoupWithdraw, setIsShowPopupWithdraw] = useState(false);
  useState(false);

  const { mutate, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({ params: { ...variables, type: "withdraw" } }),
    {
      onSuccess: (res) => {
        setIsShowPopupWithdraw(false);
        openModalDenied(true, { msg: res?.data?.reason });
      },
      onError: (error) => {
        openModalDenied(true, { msg: "SAI THÔNG TIN LIÊN KẾT VÍ!" });
      },
    }
  );

  const handleWithdraw = (amount) => {
    if (!amount) {
      return Swal.fire({
        icon: "error",
        text: `Vui lòng nhập số tiền cần rút`,
        confirmButtonText: "OK",
        heightAuto: false,
      });
    }
    if (loan?.status !== "complete") {
      return openModalDenied(true, { msg: "Hồ sơ chưa được duyệt" });
    }
    mutate({ amount });

    return null;
  };

  return (
    <>
      <WalletHeader title="Ví tiền" isShowBack />
      <div className="header-page-overlay" />
      <div className="wallet-home-page">
        <div className="container">
          <div className="wallet">
            <div className="wallet_title">
              <img src={iconBankCard} alt="bank_card" />
              <span>Tài khoản ngân hàng</span>
            </div>
            <div className="bank_card">
              <img src={user?.bank?.avatar_url || imgBankCard} alt="Test" />
              <div className="bank_num_name">
                <p className="num">
                  <span>{user?.bank_number_display}</span>
                </p>
                <p className="name">{user?.bank_user_name}</p>
              </div>
            </div>
            <div
              className="available_balances"
              onClick={() => setIsShowPopupWithdraw(true)}
            >
              <p>Số dư khả dụng:</p>
              <div className="available_balances_num">
                <p className="money">{formatNumber(user?.balance)} VNĐ</p>
              </div>
            </div>
            <div
              className="withdraw_money"
              onClick={() => handleWithdraw(user?.balance)}
            >
              <div className="withdraw_money">
                <img src={iconMoney} alt="money" />
                <p>Rút tiền về tài khoản liên kết</p>
                <img src={iconAngleSmallRight} alt="right" className="right" />
              </div>
            </div>
            <div style={{ marginTop: "10%" }}>
              <Copying />
            </div>
          </div>
        </div>
      </div>
      {isShowPopoupWithdraw && (
        <PopupWithdraw
          onSubmit={handleWithdraw}
          isOpenModal={isShowPopoupWithdraw}
          handleOpen={() => setIsShowPopupWithdraw(false)}
        />
      )}
    </>
  );
});

export default CardWallet;
