import React, { useEffect, useMemo, useState } from "react";
import { formatNumber, get } from "remix-dls";
import moment from "moment";

import { dataContract } from "_common/constants/contract";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const Contract1 = observer(({ item }) => {
  const {
    authStore: { general },
  } = useStores();
  const [duration, setDuration] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (!item) return;

    if (item?.amount) {
      setAmount(item?.amount);
    }

    if (item?.duration) {
      setDuration(item?.duration);
    }
  }, [item]);

  const amountPerMonth = useMemo(() => {
    const rate = Number(get(general, "rate", 1));
    const parseAmount = Number(get(item, "amount", 0));
    const amountPerTotal =
      Number(parseAmount) / Number(get(item, "duration", 0));
    const debitPerMonth = Number(parseAmount) * (rate / 100);
    const amountPay = Math.ceil(debitPerMonth + amountPerTotal);

    return amountPay;
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h5 className="center">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</h5>
      <h5 className="center">ĐỘC LẬP - TỰ DO - HẠNH PHÚC</h5>
      <h4 className="mini-title">HỢP ĐỒNG VAY TIỀN</h4>
      <p className="center" style={{ marginBottom: 10 }}>
        <i>Số HĐ: {item?.ref_no}</i>
      </p>
      <p>
        <span>Bên A (Bên cho vay):</span>
        <strong> {get(general, "name_company", "")}</strong>
      </p>
      <p>
        <span>Bên B (Bên vay) Ông / Bà :</span>
        <strong>
          {get(item, "created_by.first_name", "Cập nhật khi hoàn thành")}
        </strong>
      </p>
      <p>
        <span>Số CMT / CCCD :</span>
        <strong>
          {get(item, "created_by.id_proof_number", "Cập nhật khi hoàn thành")}
        </strong>
      </p>
      <p>
        <span>Ngày ký :</span>
        <strong>
          {item?.created_at
            ? moment(item?.created_at).format("DD/MM/YYYY HH:mm")
            : moment().format("DD/MM/YYYY HH:mm")}
        </strong>
      </p>
      <p>
        <span>Số tiền khoản vay :</span>
        <strong>{formatNumber(amount)} VND</strong>
      </p>
      <p>
        <span>Thời gian vay :</span>
        <strong>{formatNumber(duration)} tháng</strong>
      </p>
      <p>
        <span>Lãi suất vay : </span>
        <strong>{formatNumber(get(general, "rate", 1))}% mỗi tháng</strong>
      </p>
      {/* <p>
        <span>Số tiền đóng hàng tháng : </span>
        <strong>{formatNumber(amountPerMonth)} VND</strong>
      </p> */}
      {dataContract.map((item, key) => (
        <p key={`key-${key.toString()}`}>{item}</p>
      ))}
      <div className="sign-container">
        <div className="item">
          <p>Người vay ký</p>
          <img src={item?.sign_url} alt="Chữ ký" />
          <p style={{ marginTop: 15, fontWeight: 400 }}>
            {get(item, "created_by.first_name", "")}
          </p>
        </div>
        <div className="item">
          <p>Người đại diện</p>
          {item?.status === "complete" && (
            <>
              <img src={get(general, "sign_company", "")} alt="Công ty ký" />
              <p style={{ marginTop: 15, fontWeight: 400 }}>
                {get(general, "owner_company", "")}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default Contract1;
