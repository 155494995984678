import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Form } from "antd";
import UploadCustom from "_common/component/Upload2/UploadCustom";
import { useStores } from "_common/hooks";

const VerificationImage = observer(({ form }) => {
  const {
    toastStore,
    authStore: { user },
  } = useStores();

  const [front_image, setFrontImage] = useState([]);
  const [back_image, setBackImage] = useState([]);
  const [self_image, setSelfImage] = useState([]);

  // useEffect(() => {
  //   if (!user) return;
  //   setFrontImage({
  //     url: user?.front_image_url,
  //   });
  //   setBackImage({
  //     url: user?.back_image_url,
  //   });
  //   setSelfImage({
  //     url: user?.self_image_url,
  //   });
  // }, [user]);

  const handleReceiveFrontImage = (images) => {
    const currentImage = images
      .filter((i) => i.isNew)
      .map((i) => ({ url: i.url }));

    form.setFieldsValue({ front_image: currentImage[0]?.url });
    setFrontImage(currentImage);
  };

  const handleReceiveBackImage = (images) => {
    const currentImage = images
      .filter((i) => i.isNew)
      .map((i) => ({ url: i.url }));

    form.setFieldsValue({ back_image: currentImage[0]?.url });
    setBackImage(currentImage);
  };

  const handleReceiveSelfImage = (images) => {
    const currentImage = images
      .filter((i) => i.isNew)
      .map((i) => ({ url: i.url }));

    form.setFieldsValue({ self_image: currentImage[0]?.url });
    setSelfImage(currentImage);
  };

  return (
    <div className="group">
      <div className="group_title">ẢNH ĐỊNH DANH</div>
      <Form.Item>
        <div className="title">Mặt trước CMND/CCCD</div>
        <UploadCustom
          listType="no-image"
          maxImages={1}
          fileList={front_image}
          onReceiveImages={handleReceiveFrontImage}
          folder="kyc"
        />
        <div className="photo-input">
          {front_image.length !== 0 && (
            <img src={front_image[0]?.url} alt="Front" />
          )}
        </div>
      </Form.Item>
      <Form.Item>
        <div className="title">Mặt sau CMND/CCCD</div>
        <UploadCustom
          listType="no-image"
          maxImages={1}
          fileList={back_image}
          onReceiveImages={handleReceiveBackImage}
          folder="kyc"
        />
        <div className="photo-input">
          {back_image.length !== 0 && (
            <img src={back_image[0]?.url} alt="Back" />
          )}
        </div>
      </Form.Item>
      <Form.Item>
        <div className="title">Ảnh chân dung</div>
        <UploadCustom
          listType="no-image"
          maxImages={1}
          fileList={self_image}
          onReceiveImages={handleReceiveSelfImage}
          folder="kyc"
        />
        <div className="photo-input">
          {self_image.length !== 0 && (
            <img src={self_image[0]?.url} alt="Self" />
          )}
        </div>
      </Form.Item>
    </div>
  );
});

export default VerificationImage;
