import React from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { observer } from "mobx-react-lite";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStores } from "_common/hooks";
import { handleOpenCSKH } from "_common/utils/helper";

const PopupDeniedWithdraw = observer(() => {
  const {
    debitStore: { isShowPopupDeniedWithdraw, reason, openModalDenied },
  } = useStores();

  const onClose = () => {
    openModalDenied(false, {});
  };

  return createPortal(
    <Modal
      className="denied_withdraw_money"
      title=" "
      open={isShowPopupDeniedWithdraw}
      onCancel={onClose}
      centered
      destroyOnClose
      footer={null}
      maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
      zIndex={1000}
    >
      <div
        id="withdraw_money"
        style={{ display: "inline-block" }}
        className="fancybox-content"
      >
        <div className="icon">
          <InfoOutlinedIcon />
        </div>
        <div className="content">
          <h5>Từ Chối Yêu Cầu!</h5>
          <p className="text-red">{reason?.msg}</p>
          <p>Vui Lòng Liên Hệ CSKH!</p>
          {/* <p className="button">
            <Link
              to="/wallet/card"
              onClick={() => {
                onClose();
                // handleOpenCSKH();
              }}
            >
              Liên hệ chăm sóc khách hàng
            </Link>
          </p> */}
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
});

export default PopupDeniedWithdraw;
