import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut2 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <h4 className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU">
        Đăng ký và sử dụng ngay sau 15 phút
      </h4>
      Để giúp việc mở thẻ tín dụng trở lên nhanh chóng, thuận tiện nhất, Petrmex
      Bank áp dụng công nghệ tân tiến để hỗ trợ Khách hàng trọn vẹn trải nghiệm:
      <ul className="sc-pjSSY dAvxTh" style={{ marginTop: "1rem" }}>
        <li style={{ marginBottom: "1rem" }}>
          Chủ động đăng ký và hoàn tất thủ tục mở thẻ tín dụng trực tuyến
          (online) 100% tại bất kỳ đâu mà không cần đến chi nhánh, điểm giao
          dịch của Ngân hàng
        </li>
        <li style={{ marginBottom: "1rem" }}>
          Chỉ mất 5 phút đăng ký, Khách hàng đã có thể có thẻ sử dụng ngay
        </li>
        <li style={{ marginBottom: "1rem" }}>
          Không yêu cầu chứng minh thu nhập
        </li>
      </ul>
      Trong vòng 15 phút, hệ thống sẽ tự động đánh giá thông tin hồ sơ do Khách
      hàng cung cấp để xét duyệt và phát hành thẻ cho các Khách hàng phù hợp với
      chính sách cấp thẻ của Petrmex Bank tại từng thời kỳ. Ngay sau khi được
      phê duyệt cấp thẻ, Khách hàng chỉ cần đăng nhập vào ứng dụng Petrmex Bank
      để kích hoạt và sử dụng thẻ.
    </div>
  );
});

export default FindOut2;
