import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";
import pageSvg from "assets/icons/page.svg";
import { formatNumber } from "remix-dls";
import moment from "moment";
import { loanStatusText } from "_common/constants/statusType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import PopupPayDetail from "_common/component/Popup/PopupPayDetail";

const WalletLoanHistory = observer(() => {
  const {
    authStore: { loan, user },
    debitStore: { rate, openModalContract },
  } = useStores();
  const [isShowPopupPayDetail, setIsShowPopupPayDetail] = useState(false);

  if (!loan)
    return (
      <div className="box-content" id="user_notifications">
        <form method="post" acceptCharset="utf-8" id="form_notifications">
          <div className="tab-content">
            <div className="empty text-center" style={{ paddingTop: "1rem" }}>
              Bạn chưa đăng ký vay tiền
            </div>
          </div>
        </form>
      </div>
    );

  return (
    <>
      <WalletHeader title="Khoản vay" isShowBack />
      <div className="header-page-overlay" />
      <div className="wallet-home-page">
        <div className="container">
          <div className="loan_amount">
            <div className="wrap">
              <div className="contract_information_title">
                <img src={pageSvg} alt="" />
                <span>Thông tin hợp đồng</span>
              </div>
              <div className="list_item">
                <div className="item">
                  <div className="content">
                    <div className="item">
                      <p>Mã hợp đồng:</p>
                      <p>{loan?.ref_no}</p>
                    </div>
                    <div className="item">
                      <p>Số tiền vay:</p>
                      <p>
                        <b>{formatNumber(loan?.final_total)} VNĐ</b>
                      </p>
                    </div>
                    <div className="item">
                      <p>Hạn thanh toán:</p>
                      <p>{loan?.duration} tháng</p>
                    </div>
                    <div className="item">
                      <p>Khởi tạo lúc:</p>
                      <p>
                        {moment(loan?.created_at).format("HH:mm DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="item">
                      <p>Trạng thái hồ sơ:</p>
                      <p>{loanStatusText[loan?.status]}</p>
                    </div>
                  </div>
                  <div className="list_button">
                    <button
                      type="button"
                      className="payment_details"
                      onClick={() => setIsShowPopupPayDetail(true)}
                    >
                      <span>Chi tiết trả nợ</span>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                    <button
                      type="button"
                      className="view_contract"
                      onClick={() =>
                        openModalContract(true, {
                          ...loan,
                          duration: loan?.duration,
                          amount: loan?.final_total,
                          created_by: user,
                        })
                      }
                    >
                      Xem hợp đồng
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowPopupPayDetail && (
        <PopupPayDetail
          isOpenModal={isShowPopupPayDetail}
          handleOpen={() => setIsShowPopupPayDetail(false)}
          item={{
            ...loan,
            duration: loan?.duration,
            amount: loan?.final_total,
            created_by: user,
            rate,
          }}
        />
      )}
    </>
  );
});

export default WalletLoanHistory;
