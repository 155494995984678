import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Swal from "sweetalert2";
import { formatNumber, InputNumber } from "remix-dls";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import PopupPayDetail from "_common/component/Popup/PopupPayDetail";
import PopupSubmitLoan from "_common/component/Popup/PopupSubmitLoan";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

const debitDurationOptions = [
  {
    value: 6,
    text: "6 Tháng",
  },
  {
    value: 12,
    text: "12 Tháng",
  },
  {
    value: 18,
    text: "18 Tháng",
  },
  {
    value: 24,
    text: "24 Tháng",
  },
  {
    value: 36,
    text: "36 Tháng",
  },
  {
    value: 48,
    text: "48 Tháng",
  },
  {
    value: 60,
    text: "60 Tháng",
  },
];

const WalletLoan = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { general, isKYC, loans },
    debitStore: {
      amount,
      rate,
      duration,
      payAmountFirst,
      setRate,
      setAmount,
      setDuration,
    },
  } = useStores();
  const [isShowPopupPayDetail, setIsShowPopupPayDetail] = useState(false);
  const [isShowPopupSubmitLoan, setIsShowPopupSubmitLoan] = useState(false);

  useEffect(() => {
    setRate(Number(general?.rate ?? 1));
  }, [general?.rate]);

  const handleSubmit = () => {
    if (!isKYC) return navigate("/wallet/me/info/verification");

    if (loans?.length > 0) {
      return Swal.fire({
        icon: "error",
        text: `Bạn đang có khoản vay chưa hoàn thành, Bạn không thể đăng ký hồ sơ mới, Vui lòng liên hệ CSKH để được tư vấn và hỗ trợ`,
        confirmButtonText: "OK",
        heightAuto: false,
      });
    }
    return Swal.fire({
      icon: "info",
      text: `Đồng ý vay ${amount} VNĐ kỳ hạn ${duration} tháng`,
      confirmButtonText: "Đồng ý",
      cancelButtonText: "Hủy",
      showCancelButton: true,
      heightAuto: false,
    }).then((result) => {
      if (result.isConfirmed) setIsShowPopupSubmitLoan(true);
    });
  };

  return (
    <>
      <WalletHeader title="Đăng ký khoản vay" isShowBack />
      <div className="header-page-overlay" />
      <div className="wallet-home-page">
        <div className="container">
          <div className="loan_form">
            <div className="form_note">
              Nhập số tiền mà bạn muốn vay
              <br />
              Số tiền vay trong khoản {general?.min_mount_msg || "20 triệu"} -
              {general?.max_mount_msg || "500 triệu VNĐ"}
            </div>
            <form action="" method="post">
              <div className="row">
                <div className="col-12">
                  <InputNumber
                    inputMode="decimal"
                    className="form-control auto_price_format"
                    value={amount > 0 ? amount : null}
                    min={Number(general?.min_mount ?? 0)}
                    max={Number(general?.max_mount ?? 0)}
                    onChange={(value) => {
                      setAmount(value || 0);
                    }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    placeholder="Nhập số tiền cần vay"
                  />
                </div>
                <div className="col-12">
                  <p className="payment_term">Thời hạn thanh toán</p>
                </div>
                {debitDurationOptions?.map((item) => (
                  <div className="col-6" key={item.value}>
                    <div
                      className="form-group-area odd"
                      onClick={() => setDuration(item.value)}
                    >
                      <label htmlFor="6-thang">
                        <input
                          name="payment_term"
                          type="radio"
                          value={item.value}
                          id={`${item.value}-duration`}
                          checked={duration === item.value}
                          readOnly
                        />
                        <span>{item.text}</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="info_loan">
                <div className="item">
                  <p>Trả nợ kỳ đầu</p>
                  <p id="first_period" className="num">
                    {formatNumber(payAmountFirst)} VNĐ
                  </p>
                </div>
                <div className="item">
                  <p>Lãi suất hàng tháng</p>
                  <p className="num">
                    <span id="loan_interest">{rate}</span>%
                  </p>
                </div>
                <div className="item d-none">
                  <p id="datestart">2023-08-22</p>
                </div>
              </div>
              <div className="pay_detail">
                <button
                  type="button"
                  onClick={() => setIsShowPopupPayDetail(true)}
                >
                  <span>Chi tiết trả nợ</span>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
              </div>
              <div className="submit">
                <button
                  type="button"
                  className="submitLoan"
                  onClick={handleSubmit}
                >
                  Tiếp tục
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isShowPopupPayDetail && (
        <PopupPayDetail
          isOpenModal={isShowPopupPayDetail}
          handleOpen={() => setIsShowPopupPayDetail(false)}
          item={{
            amount,
            duration,
            rate,
          }}
        />
      )}
      {isShowPopupSubmitLoan && (
        <PopupSubmitLoan
          isOpenModal={isShowPopupSubmitLoan}
          handleOpen={() => setIsShowPopupSubmitLoan(false)}
          amount={amount}
          duration={duration}
        />
      )}
    </>
  );
});

export default WalletLoan;
