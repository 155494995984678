import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { Modal, Table } from "antd";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";

export default function PopupPayDetail({ isOpenModal, handleOpen, item }) {
  const [duration, setDuration] = useState(0);
  const [amount, setAmount] = useState(0);
  const [rate, setRate] = useState(1);

  useEffect(() => {
    if (!isOpenModal) return;

    if (item?.amount) {
      setAmount(item?.amount);
    }

    if (item?.rate) {
      setRate(item?.rate);
    }

    if (item?.duration) {
      setDuration(item?.duration);
    }
  }, [isOpenModal, item]);

  const dataSource = useMemo(() => {
    try {
      if (!isOpenModal) return [];
      if (!duration) return [];
      if (!amount) return [];
      const result = [];
      // @ts-ignore
      const parseAmount = `${amount}`.replaceAll(".", "").replaceAll(",", "");
      let amountPay = 0;
      let nextAmount = parseAmount;
      const amountPerTotal = Number(parseAmount) / Number(duration);

      for (let index = 0; index < duration; index += 1) {
        if (index > 0) {
          nextAmount -= amountPerTotal;
        }
        const debitPerMonth = Number(nextAmount) * (rate / 100);

        amountPay = Math.ceil(debitPerMonth + amountPerTotal);

        const element = {
          key: index.toString(),
          id: index.toString(),
          name: `Kỳ thứ ${index + 1}`,
          amount: `${formatNumber(amountPay)} đ`,
          date: moment()
            .add(index + 1, "month")
            .format("DD-MM-YYYY"),
        };

        result.push(element);
      }

      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  }, [duration, amount, isOpenModal]);

  const columns = [
    {
      title: "Kỳ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Ngày đóng",
      dataIndex: "date",
      key: "date",
    },
  ];

  return createPortal(
    <Modal
      className="pay_detail"
      title="Chi tiết trả nợ"
      open={isOpenModal}
      onCancel={handleOpen}
      centered
      destroyOnClose
      footer={null}
      maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
      zIndex={1400}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 24 }}
      />
    </Modal>,
    document.getElementById("root")
  );
}
