import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import {
  formatCardNumber,
  formatDate,
  handleCopyLink,
} from "_common/utils/helper";
import { Button, Col, Row } from "antd";
import { optionBank2 } from "_common/constants/bank_2";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { PaymentApi } from "states/api/payment";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "_common/utils/formatValue";

const Notify = observer(() => {
  const {
    authStore: { user },
  } = useStores();
  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["payment", "balances", params],
    () =>
      PaymentApi.getBalanceHistories({
        params: {
          is_self: 0,
          page: 1,
          limit: 20,
          entity_type: "add",
          user_id: user?.id,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="card-wallet-page">
      <WalletHeader title="Lịch sử giao dịch" />
      <div className="header-page-overlay" />
      <div className="card-wallet-container">
        <div className="history-list">
          {data?.data?.map((item) => (
            <div className="history-item" key={item?.id}>
              <Row>
                <Col span={12}>
                  <p>{item?.description}</p>
                </Col>

                <Col span={12} style={{ textAlign: "right" }}>
                  {item?.entity_type === "sub" && "-"}
                  {item?.entity_type === "add" && "+"}
                  {formatCurrency(item?.amount)}
                </Col>
              </Row>
              <span>{formatDate(item?.created_at, "DD/MM/YYYY")}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Notify;
