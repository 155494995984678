import React from "react";
import { Button, Upload } from "antd";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";

import classnames from "classnames";

function UploadButtonUI({ textDisplay, imgSrc }) {
  return (
    <div className="box-upload-image-container">
      <div className="box-upload-image">
        {imgSrc ? (
          <img src={imgSrc} alt={textDisplay} />
        ) : (
          <>
            <CameraOutlined style={{ fontSize: 40 }} />
            <p>{textDisplay}</p>
          </>
        )}
      </div>
    </div>
  );
}

function UploadButton({
  fileList = [],
  onPreview,
  listType = "picture-card",
  onChange,
  maxImages,
  onUpload,
  disabled,
  onRemove,
  typeUpload,
  textDisplay,
  imgSrc,
}) {
  const _renderUploadButton = () => {
    if (listType === "picture-card")
      return <UploadButtonUI textDisplay={textDisplay} imgSrc={imgSrc} />;
    return (
      <div className="for_pic">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
        <span>Chọn hình…</span>
      </div>
    );
  };

  return (
    <Upload
      className={classnames({
        avatar:
          maxImages !== undefined &&
          maxImages === fileList?.length &&
          typeUpload === "avatar",
      })}
      disabled={disabled}
      name="image"
      customRequest={onUpload}
      // @ts-ignore
      listType={listType}
      fileList={maxImages > 1 ? fileList : []}
      onPreview={onPreview}
      onChange={onChange}
      onRemove={onRemove}
      maxCount={maxImages}
    >
      {!disabled && _renderUploadButton()}
    </Upload>
  );
}

export default UploadButton;
