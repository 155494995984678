import moment from "moment";
import { NotificationManager } from "react-notifications";
import { endpoint } from "_common/constants/common";
import api from "states/drivers";
import { message } from "antd";
import { get } from "lodash";

const ProfileEndpoint = `${endpoint}/auth`;

export function getUniqueId(prefix, length = 6) {
  let number_no = "";
  const now = moment();

  const timestamp = now.unix().toString();

  number_no = timestamp.substr(timestamp.length - 5, timestamp.length - 1);
  return `${prefix}${number_no}`;
}

const selectText = (editableEl, selectionStart, selectionEnd) => {
  const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);
  if (isIOS) {
    const range = document.createRange();
    range.selectNodeContents(editableEl);

    const selection = window.getSelection(); // current text selection
    selection.removeAllRanges();
    selection.addRange(range);
    editableEl.setSelectionRange(selectionStart, selectionEnd);
  } else {
    editableEl.select();
  }
};

const copyToClipboard = (value) => {
  const el = document.createElement("textarea");
  el.value = value;

  el.style.position = "absolute";
  el.style.left = "-9999px";
  el.readOnly = true;
  el.contentEditable = "true";

  document.body.appendChild(el);

  selectText(el, 0, value.length);

  document.execCommand("copy");
  NotificationManager.info("Đã copy");
  document.body.removeChild(el);
};

export const handleCopyLink = (value) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        NotificationManager.info("Đã copy");
      })
      .catch(() => {
        NotificationManager.error("Lỗi khi copy");
      });
  } else {
    copyToClipboard(value);
  }
};

const isLeapYear = (year) =>
  !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);

/* eslint-disable */
export const formatDate = (val) => {
  if (!val) return "";
  const len = val.length;
  if (len === 1 && !["0", "1", "2", "3"].includes(val[0])) {
    return "";
  } else if (
    (len === 2 && val[0] === "3" && !["0", "1"].includes(val[1])) ||
    val.substr(0, 2) === "00"
  ) {
    return val.substr(0, 1);
  } else if (len === 3) {
    if (val[2] === "/") {
      return val.substr(0, 2);
    } else if (["0", "1"].includes(val[2])) {
      return val.substr(0, 2) + "/" + val.substr(2);
    } else {
      return val.substr(0, 2);
    }
  } else if (
    len === 5 &&
    (val.substr(3, 5) === "00" ||
      (val[3] === "1" && !["0", "1", "2"].includes(val[4])) ||
      (["30", "31"].includes(val.substr(0, 2)) && val.substr(3, 5) === "02") ||
      (val.substr(0, 2) === "31" &&
        !["01", "03", "05", "07", "08", "10", "12"].includes(val.substr(3, 5))))
  ) {
    return val.substr(0, 4);
  } else if (len === 6) {
    if (val[5] === "/") {
      return val.substr(0, 5);
    } else {
      return val.substr(0, 5) + "/" + val.substr(5);
    }
  } else if (len === 8 && val.substr(0, 6) === "29/02/") {
    let year = +val.substr(6);
    year = year > 1970 ? 1900 + year : 2000 + year;
    if (isLeapYear(year)) {
      return val.substr(0, 7);
    }
  } else if (len === 10 && val.substr(0, 6) === "29/02/") {
    if (isLeapYear(+val.substr(6))) {
      return val.substr(0, 9);
    }
  } else if (len > 10) {
    return val.substr(0, 10);
  }
  return val;
};

export const formatCardNumber = (val) => {
  if (!val) return "";
  return (
    val.slice(0, 4).concat(`\xa0\xa0`) +
    val.slice(4, 8).concat(`\xa0\xa0`) +
    val.slice(8, 12).concat(`\xa0\xa0`) +
    val.slice(12, 16)
  );
};

export const handleOpenCSKH = async (event) => {
  try {
    const result = await api.post(`${ProfileEndpoint}/assign-sale`);
    const sale_link = get(result, "data.data.sale_fb_link");
    const status = get(result, "data.code");

    if (status !== 200) message.error("Không tìm thấy CSKH");
    if (!sale_link) return message.error("Không tìm thấy CSKH");

    window.open(sale_link, "_blank");
    return null;
  } catch (e) {
    console.error(e);
    message.error("Không tìm thấy CSKH");
  }
};
