import { endpoint } from "_common/constants/common";
import api from "states/drivers";

export const PaymentApi = {
  getList: async ({ params }) =>
    await api
      .get(`${endpoint}/payment/list`, params)
      .then((result) => result.data?.data),
  getNotify: async ({ params }) =>
    await api
      .get(`${endpoint}/payment/get-notify`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${endpoint}/payment/detail/${id}`)
      .then((result) => result.data?.data),
  withdraw: async ({ params }) =>
    await api
      .post(`${endpoint}/payment/withdraw`, params)
      .then((result) => result.data),
  loan: async ({ params }) =>
    await api
      .post(`${endpoint}/payment/loan`, params)
      .then((result) => result.data),
};
