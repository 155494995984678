import React, { useState } from "react";
import { createPortal } from "react-dom";
import { InputNumber, Modal } from "antd";
import Swal from "sweetalert2";
import { observer } from "mobx-react-lite";
import { PaymentApi } from "states/api/payment";
import { useMutation } from "@tanstack/react-query";
import { useStores } from "_common/hooks";

const PopupWithdraw = observer(({ isOpenModal, onSubmit, handleOpen }) => {
  const [amount, setAmount] = useState();

  return createPortal(
    <Modal
      className="withdraw_money_form"
      title="RÚT TIỀN"
      open={isOpenModal}
      onCancel={handleOpen}
      centered
      destroyOnClose
      footer={null}
      maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
      zIndex={1400}
    >
      <form action="" method="post" id="withdraw_form" noValidate="novalidate">
        <div className="form-group">
          <InputNumber
            inputMode="decimal"
            className="form-control input_withdraw"
            value={amount > 0 ? amount : null}
            min={0}
            onChange={(value) => {
              setAmount(value || 0);
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            placeholder="Nhập số tiền rút"
          />
        </div>
        <button type="button" onClick={() => onSubmit(amount)}>
          Gửi
        </button>
      </form>
    </Modal>,
    document.getElementById("root")
  );
});

export default PopupWithdraw;
