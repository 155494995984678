import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "_common/hooks";

import congthuongImage from "assets/img/congthuong.png";

const Copying = observer(() => {
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="copyright">
      <img src={congthuongImage} alt="" />
      <p>{general?.description_website}</p>
    </div>
  );
});

export default Copying;
