import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut4 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <h4 className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU">
        Miễn phí thường niên năm đầu tiên
      </h4>
      Nhanh tay mở thẻ Petromex để không bỏ lỡ chương trình miễn phí thường niên
      năm đầu tiên mà không cần bất kỳ điều kiện nào kèm theo
    </div>
  );
});

export default FindOut4;
