import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut3 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <span className="sc-fzqNqU sc-paXsP kTnDQL">
        Hiện thực hoá ước mơ sở hữu bất cứ món đồ công nghệ mới nhất: iPhone,
        New Macbook… hay những món đồ nội thất sang trọng mà vẫn đảm bảo quản lý
        tài chính cá nhân hiệu quả bằng tính năng trả góp qua thẻ tín dụng
      </span>
      <h4
        className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU"
        style={{ marginBottom: "0.5rem" }}
      >
        Lợi ích của chương trình
      </h4>
      <ul
        className="sc-pjSSY dAvxTh"
        style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
      >
        <li style={{ marginBottom: "1rem" }}>
          0% lãi suất/tháng
          <br />
          <div style={{ marginTop: "1rem" }}>
            Phí chuyển đổi đối với những giao dịch trả góp nằm trong hệ thống
            đối tác hợp tác trả góp của Petromex (Tham khảo thông tin đối tác
            chi tiết tại :
            <a
              href="https://petrolimexcard.com"
              role="button"
              className="sc-AxhUy sc-qQmou dVBRWK"
            >
              &nbsp;https://petrolimexcard.com
            </a>
            )
          </div>
        </li>
        <li style={{ marginBottom: "0.5rem" }}>
          Trả góp mọi giao dịch thẻ tại mọi điểm chấp nhận thẻ với phí chuyển
          đổi trả góp linh hoạt:
        </li>
      </ul>
      <ul className="sc-pjSSY dAvxTh" style={{ marginLeft: "1.2rem" }}>
        <li>2.99% áp dụng với kỳ hạn 3, 6 tháng;</li>
        <li>4.99% kỳ hạn 9, 12 tháng.</li>
      </ul>
      <h4
        className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU"
        style={{ marginBottom: "0.5rem" }}
      >
        Cách thức đăng ký
      </h4>
      <ul
        className="sc-pjSSY dAvxTh"
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <li style={{ marginBottom: "1rem" }}>
          Đối với chương trình trả góp 1.2% lãi suất, không phí áp dụng tại các
          đối tác hợp tác của Petrmex thân thiết: Chủ thẻ đăng ký trả góp giao
          dịch ngay tại thời điểm giao dịch/mua sắm tại điểm chấp nhận thẻ
        </li>
        <li style={{ marginBottom: "1rem" }}>
          Đối với chương trình trả góp 1.2% lãi suất linh hoạt: Chủ thẻ thực
          hiện yêu cầu trả góp tại ứng dụng
          <a
            href="https://tpb-ebankx.onelink.me/As35/website"
            role="button"
            className="sc-AxhUy sc-qQmou dVBRWK"
          >
            &nbsp; Petrmex Bank &nbsp;
          </a>
          hoặc liên hệ đến Tổng đài&nbsp;
          <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
            1900633554
          </span>
          &nbsp;/&nbsp;
          <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
            1900633554 &nbsp;
          </span>
          để đăng ký chuyển đổi trả góp
        </li>
        <li style={{ marginBottom: "1rem" }}>
          Mọi chi tiết thêm về thể lệ chương trình, Khách hàng vui lòng tham
          khảo tại : website của Petrmex Bank:
        </li>
      </ul>
      Mọi chi tiết thêm về thể lệ chương trình, Khách hàng vui lòng tham khảo
      tại : website của Petrmex Bank:&nbsp;
      <a
        href="https://petrolimexcard.com"
        role="button"
        className="sc-AxhUy sc-qQmou dVBRWK"
      >
        https://petrolimexcard.com
      </a>
      hoặc liên hệ hotline:&nbsp;
      <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
        1900633554
      </span>
    </div>
  );
});

export default FindOut3;
