import React from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Select } from "antd";

const VerificationRelative = observer(() => (
  <div className="group">
    <div className="group_title">Người liên quan</div>
    <Form.Item
      name="relative_phone"
      rules={[
        {
          required: true,
          message: "Không được để trống",
        },
      ]}
    >
      <Input placeholder="Số điện thoại người thân" />
    </Form.Item>
    <Form.Item
      name="relative_type"
      rules={[
        {
          required: true,
          message: "Không được để trống",
        },
      ]}
    >
      <Input placeholder="Mối quan hệ với người thân" />
    </Form.Item>
  </div>
));

export default VerificationRelative;
