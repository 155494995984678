import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import imgAvatar from "assets/img/Me/default-avatar.png";
import iconUser from "assets/img/Me/user_icon.svg";
import iconBankCard from "assets/img/Me/bank_card.svg";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CopyFilled } from "@ant-design/icons";
import { handleCopyLink } from "_common/utils/helper";

const BankInfo = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, general },
  } = useStores();
  const [isShowBank, setIsShowBank] = useState(false);
  const [isShowBankNumber, setIsShowBankNumber] = useState(false);

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div className="wallet-home-page">
      <WalletHeader title="Tài khoản thanh toán" isShowBack />
      <div className="header-page-overlay" />
      <div className="container box-account">
        <div className="col-12 group user_information">
          <div className="form-group">
            <div className="title">Tên ngân hàng</div>
            <p>
              {general?.bank_name}
              <CopyFilled
                className="btn-copy"
                onClick={() => handleCopyLink(general?.bank_name)}
              />
            </p>
          </div>
          <div className="form-group">
            <div className="title">Số tài khoản</div>
            <p>
              {general?.bank_number}
              <CopyFilled
                className="btn-copy"
                onClick={() => handleCopyLink(general?.bank_number)}
              />
            </p>
          </div>
          <div className="form-group">
            <div className="title">Chủ tài khoản</div>
            <p>
              {general?.bank_owner}
              <CopyFilled
                className="btn-copy"
                onClick={() => handleCopyLink(general?.bank_owner)}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BankInfo;
