import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import ModalContractDetail from "_common/component/ContractDocument/ModalContractDetail";
import PopupDeniedWithdraw from "_common/component/Popup/PopupDeniedWithdraw";
import WalletFooter from "../Footer/WalletFooter";

const WalletLayout = observer(() => {
  const {
    authStore: { authenticated },
    debitStore: { isModalContract, isShowPopupDeniedWithdraw },
  } = useStores();

  if (!authenticated) return <PageLoading />;
  return (
    <Layout className="sc-pbYBj gDaToN">
      <div className="layout-wrapper">
        <Outlet />
        <WalletFooter />
      </div>
      {isModalContract && <ModalContractDetail />}
      {isShowPopupDeniedWithdraw && <PopupDeniedWithdraw />}
    </Layout>
  );
});

export default WalletLayout;
