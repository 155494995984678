export const dataContract = [
  "Hợp đồng nêu rõ các bên đã đạt được thỏa thuận vay sau khi thương lượng và trên cơ sở bình đẳng , tự nguyện và nhất trí . Tất cả các bên cần đọc kỹ tất cả các điều khoản trong thỏa thuận này, sau khi ký vào thỏa thuận này coi như các bên đã hiểu đầy đủ và đồng ý hoàn toàn với tất cả các điều khoản và nội dung trong thỏa thuân này.",
  "1.Phù hợp với các nguyên tắc bình đẳng , tự nguyện , trung thực và uy tín , hai bên thống nhất ký kết hợp đồng vay sau khi thương lượng và cùng cam kết thực hiện.",
  "2.Bên B cung cấp tài liệu đính kèm của hợp đồng vay và có hiệu lực pháp lý như hợp đồng vay này.",
  "3.Bên B sẽ tạo lệnh tính tiền gốc và lãi dựa trên số tiền vay từ ví ứng dụng do bên A cung cấp.",
  "4.Điều khoản đảm bảo.",
  "- Bên vay không được sử dụng tiền vay để thực hiện các hoạt động bất hợp pháp .Nếu không , bên A có quyền yêu cầu bên B hoàn trả ngay tiền gốc và lãi , bên B phải chịu các trách nhiêm pháp lý phát sinh từ đó.",
  "- Bên vay phải trả nợ gốc và lãi trong thời gian quy định hợp đồng. Đối với phần quá hạn , người cho vay có quyền thu hồi nơ trong thời hạn và thu ( lãi quá hạn ) % trên tổng số tiền vay trong ngày.",
  "- Gốc và lãi của mỗi lần trả nợ sẽ được hệ thống tự động chuyển từ tài khoản ngân hàng do bên B bảo lưu sang tài khoản ngân hàng của bên A . Bên B phải đảm bảo có đủ tiền trong tài khoản ngân hàng trước ngày trả nợ hàng tháng.",
  "5.Chịu trách nhiệm do vi pham hợp đồng",
  "- Nếu bên B không trả được khoản vay theo quy định trong hợp đồng. Bên B phải chịu các khoản bồi thường thiệt hại đã thanh lý và phí luật sư, phí kiện tụng, chi phí đi lại và các chi phí khác phát sinh do kiện tụng.",
  "- Khi bên A cho rẳng bên B đã hoặc có thể xảy ra tình huống ảnh hưởng đến khoản vay thì bên A có quyền yêu cầu bên B phải trả lại kịp thời trược thời hạn.",
  "- Người vay và người bảo lãnh không được vi phạm điều lệ hợp đồng vì bất kỳ lý do gì",
  "6.Phương thức giải quyết tranh chấp hợp đồng. ",
  "Tranh chấp phát sinh trong quá trình thực hiện hợp đồng này sẽ được giải quyết thông qua thương lượng thân thiện giữa các bên hoặc có thể nhờ bên thứ ba làm trung gian hòa giải .Nếu thương lượng hoặc hòa giải không thành , có thể khởi kiện ra tòa án nhân dân nơi bên A có trụ sở.",
  "7.Khi người vay trong quá trình xét duyệt khoản vay không thành công do nhiều yếu tố khác nhau như chứng minh thư sai, thẻ ngân hàng sai , danh bạ sai. Việc thông tin sai lệch này sẽ khiến hệ thống phát hiện nghi ngờ gian lận hoặc giả mạo khoản vay và bên vay phải chủ động hợp tác với bên A để xử lý.",
  "8.Nếu không hợp tác. Bên A có quyền khởi kiện ra Tòa án nhân dân và trình báo lên Trung tâm Báo cáo tín dụng của Ngân hàng nhà nước Việt Nam , hồ sơ nợ xấu sẽ được phản ánh trong báo cáo tín dụng , ảnh hưởng đến tín dụng sau này của người vay , vay vốn ngân hàng và hạn chế tiều dùng của người thân , con cái người vay ...",
  "Khi hồ sơ khách hàng được chấp thuận .Tức hợp đồng giữa hai bên có hiệu lực , Bên B  phải có trách nhiệm và nghĩa vụ phải thanh toán lãi suất hàng tháng cho bên A , Số tiền lãi và gốc hàng tháng được tính theo phương thức giảm dần . Nếu bên A hoặc B làm trái với quy định hợp đồng thì sẽ phải chịu trách nhiệm bồi thường thiệt hại và xử lý trước pháp luật..",
  "9. Hồ sơ khoản vay của bên B được xét duyệt và giải ngân . trong trường hợp bên B tự ý hủy hợp đồng khoản vay bắt buộc phải bồi thường 8-20% giá trị khoản vay cho bên A theo Điều 2 khoản 468 Bộ luật dân sự 2015.",
  "10.Theo điều 345 Bộ luật dân sự 2015 thì hình thức  , nội dung tín chấp được quy định như sau :-Hình thức cho vay tín chấp : Việc cho vay có bản đảm bằng tín chấp phải được lập thành văn bản có xác nhận cả tổ Chính Trị - Xã Hội bảo đảm bằng tín chấp về điều kiện , hoàn cảnh của bên vay vốn và nếu bên vay vốn có hình thức giả mạo và không thực hiện theo như hợp đồng đã ký kết thì bên công ty tài chính có thể khiếu nại và yêu cầu bên phòng hỗ trợ Pháp Lý ra quyết định cho bên A hay gọi là bên vay phải hoàn tất thủ tục đóng lãi hàng tháng như hợp đồng đã ký kết nếu như bên A bên vay không chấp hành theo quy định của phòng Pháp Lý  thì bên Phòng hỗ trợ tư pháp có thể ra quyết định yêu cầu cơ quan công an triệu tập bên A để xử lý  và giải quyết trách nhiệm trước Pháp Luật .",
];
