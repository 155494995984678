import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut1 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <h4 className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU">
        Ưu đãi đặc quyền của thẻ Petrmex Bank
      </h4>
      <span className="sc-fzqNqU sc-paXsP kTnDQL">
        Giúp Khách hàng mua sắm thả phanh với giá trị hoàn tiền lên đến 10% cho
        các giao dịch chi tiêu mua sắm trực tuyến tại: Shopee, Tiki, Lazada,
        Amazon, Alibaba, Grab,... cụ thể:
      </span>
      <ul className="sc-pjSSY dAvxTh" style={{ marginTop: "1rem" }}>
        <li style={{ marginBottom: "1rem" }}>
          <span color="#43236C" className="sc-fzqNqU sc-prOVx dFKciA">
            Hoàn tiền lên đến 10%&nbsp;
          </span>
          cho các giao dịch mua sắm trực tuyến qua thẻ (giao dịch Online) nếu
          tổng giá trị giao dịch trong tháng đó của Khách hàng đạt từ 30 triệu
          VND trở lên
        </li>
        <li style={{ marginBottom: "1rem" }}>
          <span color="#43236C" className="sc-fzqNqU sc-prOVx dFKciA">
            Hoàn tiền lên đến 5%&nbsp;
          </span>
          cho các giao dịch mua sắm trực tuyến qua thẻ (giao dịch Online) nếu
          tổng giá trị giao dịch trong tháng đó của Khách hàng trong mức từ 10
          triệu VND đến 30 triệu VND
        </li>
        <li style={{ marginBottom: "1rem" }}>
          <span color="#43236C" className="sc-fzqNqU sc-prOVx dFKciA">
            Hoàn tiền lên đến 1%&nbsp;
          </span>
          cho các giao dịch mua sắm trực tuyến qua thẻ (giao dịch Online) nếu
          tổng giá trị giao dịch trong tháng đó của Khách hàng dưới 10 triệu VND
          trong tháng
        </li>
      </ul>
      <span className="sc-fzqNqU sc-paXsP kTnDQL">
        Mọi chi tiết thêm về thể lệ chương trình, Khách hàng vui lòng tham
        khảo&nbsp;
        <a
          href="https://petrolimexcard.com"
          role="button"
          className="sc-AxhUy sc-qYiqT dgUwPZ"
        >
          tại đây&nbsp;
        </a>
        hoặc liên hệ hotline:&nbsp;
        <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
          1900633554
        </span>
      </span>
    </div>
  );
});

export default FindOut1;
