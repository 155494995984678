import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { message, Modal } from "antd";
import Swal from "sweetalert2";
import SignatureCanvas from "react-signature-canvas";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { observer } from "mobx-react-lite";
import { useMutation } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { useNavigate } from "react-router-dom";
import { handleOpenCSKH } from "_common/utils/helper";

const PopupSubmitLoan = observer(({ isOpenModal, handleOpen }) => {
  const navigate = useNavigate();
  const canvasRef = useRef();
  const {
    debitStore: { duration, amount, openModalContract },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => PaymentApi.loan({ params: { ...variables, type: "loan" } }),
    {
      onSuccess: (res) => {
        handleOpen();
        Swal.fire({
          icon: "success",
          text: `Bạn đã đăng ký thành công, vui lòng quay lại nhắn tin cho nhân viên.`,
          confirmButtonText: "Liên hệ CSKH",
          heightAuto: false,
        }).then((result) => {
          handleOpenCSKH();
          navigate("/wallet/card");
        });
      },
      onError: (error) => {
        message.error(error?.message ?? "Vui lòng thử lại!");
      },
    }
  );

  const handleSubmit = () => {
    if (!amount) return message.error("Không tìm thấy khoản vay.");
    if (!duration) return message.error("không tìm thấy kỳ hạn của khoản vay.");

    const signImage = canvasRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    mutate({
      amount,
      duration,
      sign: signImage,
    });

    return null;
  };

  const handleRenewCanvas = () => {
    canvasRef.current.clear();
  };

  return createPortal(
    <Modal
      title="Ký tên"
      className="popup-submit-loan"
      open={isOpenModal}
      onCancel={handleOpen}
      footer={null}
      zIndex={1000}
    >
      <>
        <div className="text-left">
          <div>Khoản tiền vay: {formatNumber(amount)} vnđ</div>
          <div>Thời hạn thanh toán: {duration} tháng</div>
          <div
            className="text-primary mb-3"
            onClick={() => openModalContract(true, { duration, amount })}
          >
            Xem hợp đồng
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <SignatureCanvas
            ref={canvasRef}
            penColor="black"
            canvasProps={{
              width: "auto",
              height: 300,
              className: "sigCanvas",
            }}
          />
        </div>
        <div className="swal2-actions">
          <button
            type="button"
            className="swal2-confirm swal2-styled"
            style={{ display: "inline-block" }}
            onClick={handleSubmit}
          >
            Gửi yêu cầu vay
          </button>
          <button
            type="button"
            className="swal2-deny swal2-styled"
            style={{ display: "inline-block" }}
            onClick={handleRenewCanvas}
          >
            Ký lại
          </button>
          <button
            type="button"
            className="swal2-cancel swal2-styled"
            style={{ display: "inline-block" }}
            onClick={handleOpen}
          >
            Cancel
          </button>
        </div>
      </>
    </Modal>,
    document.getElementById("root")
  );
});

export default PopupSubmitLoan;
