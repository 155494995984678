import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";

const MeInfoVerified = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Xác thực tài khoản" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Xác thực tài khoản</div>
          <div className="detail-info">
            <span>Mặt trước CMND / CCCD</span>
            <img src={user?.front_image_url} alt="User Front" />
            <span>Mặt sau CMND / CCCD</span>
            <img src={user?.back_image_url} alt="User Back" />
            <span>Ảnh chân dung</span>
            <img src={user?.self_image_url} alt="User Self" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeInfoVerified;
