import React from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Select } from "antd";
import { formatDate } from "_common/utils/helper";

const VerificationInfo = observer(({ form }) => {
  const handleChangeBirthday = (event) => {
    form.setFieldsValue({ dob: formatDate(event.target.value) });
  };

  return (
    <div className="group">
      <div className="group_title">Thông tin cá nhân</div>
      <Form.Item
        name="first_name"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Họ Tên" />
      </Form.Item>
      <Form.Item
        name="gender"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Select placeholder="Giới tính">
          <Select.Option value="Nam">Nam</Select.Option>
          <Select.Option value="Nữ">Nữ</Select.Option>
          <Select.Option value="Khác">Khác</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="id_proof_number"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Số CMND/CCCD" />
      </Form.Item>
      <Form.Item
        name="dob"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input
          onChange={handleChangeBirthday}
          placeholder="Ngày/Tháng/Năm sinh"
        />
      </Form.Item>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Địa chỉ" />
      </Form.Item>
      <Form.Item
        name="employ_status"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn",
          },
        ]}
      >
        <Select
          placeholder="Trình độ học vấn"
          options={[
            {
              value: "Trung học phổ thông",
              label: "Trung học phổ thông",
            },
            { value: "Cao đẳng / Đại học", label: "Cao đẳng / Đại học" },
            { value: "Sau đại học", label: "Sau đại học" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="debit_reason"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Mục đích khoản vay" />
      </Form.Item>
      <Form.Item
        name="salary"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn",
          },
        ]}
      >
        <Select
          placeholder="Thu nhập"
          options={[
            { value: "Dưới 5 triệu", label: "Dưới 5 triệu" },
            { value: "Từ 5 đến 10 triệu", label: "Từ 5 đến 10 triệu" },
            {
              value: "Từ 10 đến 20 triệu",
              label: "Từ 10 đến 20 triệu",
            },
            { value: "Trên 20 triệu", label: "Trên 20 triệu" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="marriage"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn",
          },
        ]}
      >
        <Select
          placeholder="Tình trạng hôn nhân"
          options={[
            { value: "Độc thân", label: "Độc thân" },
            { value: "Đã kết hôn", label: "Đã kết hôn" },
            {
              value: "Li hôn",
              label: "Li hôn",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="job"
        rules={[
          {
            required: true,
            message: "Không được để trống",
          },
        ]}
      >
        <Input placeholder="Nghề nghiệp" />
      </Form.Item>
    </div>
  );
});

export default VerificationInfo;
