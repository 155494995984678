import React from "react";
import { Link, useLocation } from "react-router-dom";

import iconHome from "assets/icons/home.svg";
import iconPlus from "assets/icons/plus.svg";
import iconUser from "assets/icons/user.svg";

export default function WalletFooter() {
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <footer className="footer-page">
      <div className="container">
        <div className="list_button">
          <div className="item">
            <Link
              to="/wallet"
              className={`${pathName === "/wallet" && "current"}`}
            >
              <img src={iconHome} alt="home" />
            </Link>
          </div>
          <div className="button_middle">
            <Link
              to="/wallet/loan"
              className={`${pathName === "/wallet/loan" && "current"}`}
            >
              <img src={iconPlus} alt="register" />
            </Link>
          </div>
          <div className="item">
            <Link
              to="/wallet/me"
              className={`${pathName === "/wallet/me" && "current"}`}
            >
              <img src={iconUser} alt="home" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
